import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UIDialogChangePushFixed from "@/components/UlDialogChangePushFixed.vue";
import { SORT_ORDER_DESC, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/push/request";
import PushSearch, { ListItem } from "@/store/push/search";
import Flash, { SuccessAlert, ErrorAlert } from "@/store/common/flash";
import PushModify from "@/store/push/modify";
import PushChangeFixed from "@/store/push/changePushFixed";
import { DeleteRequest } from "@/api/push/request";
import { ChangeFixedRequest } from "@/api/push/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDataTable,
    UIDatePicker,
    UIDialogDelete,
    UIDialogChangePushFixed
  }
})
export default class List extends Vue {
  @Prop({ required: true })
  shopId!: number;

  shopName = "";

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "プッシュメッセージ";
  headingSub = "Push notifications";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "プッシュメッセージ", disabled: true },
    { text: "店舗一覧", to: { name: "push-notifications" } },
    { text: "プッシュメッセージ一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "タイトル", value: "title" },
    { text: "配信日", value: "sendDate" },
    { text: "配信時間", value: "sendTime" },
    { text: "配信状況", value: "sendFlg" },
    { text: "固定表示", value: "fixedFlg" },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "固定設定",
      text: "",
      value: "fixed",
      sortable: false
    }
  ];
  
  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // 削除ダイアログの表示有無
  showDialog = false;

  // 固定表示変更ダイアログの表示有無
  showChangeDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるメッセージID
  deletingId = 0 as number;
  
  //固定表示が変更されるタイトル
  fixedChangeTitle = "";

  // 固定表示が変更されるメッセージID
  fixedChangeId = 0 as number;

  //固定表示の設定値
  fixedStatus = "";

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    sendFlg: "all" as "all" | "reserved" | "sending" | "delivered" | "error",
    startDate: null as string | null,
    endDate: null as string | null,
    title: null as string | null,
    status: 0 as number | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions: TableOptions = {
    page: 1,
    displayCount: 20,
    sortKey: "title",
    sortOrder: SORT_ORDER_DESC
  };

  // ------------

  /**
   * 新規作成権限の有無
   */
  get writeFlg() {
    return PushSearch.getWriteFlg;
  }

  get pushLinkFlg() {
    return PushSearch.getPushLinkFlg;
  }

  // NEW_DEV-1437 cyber start
  get isMainShop() {
    return PushSearch.getIsMainShop;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return PushSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return PushSearch.getTotalCount;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    // 店舗名を表示
    if (this.$route.query.shopName) {
      this.shopName = String(this.$route.query.shopName);
    }

    await PushSearch.restore(this.inputOptions as SearchRequest);
    const request = PushSearch.getSearchRequest;
    // 検索入力オプション
    this.inputOptions = request;

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await PushSearch.clearResponse();
    await PushModify.clearResponse();
    await PushChangeFixed.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    if (this.tableOptions.sortKey == null) {
      this.tableOptions.sortKey = "title";
    }
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * 配信日（開始）UIDatePickerのコールバック
   *
   * @param date 選択日付
   */
  changeStartDateCallback(date: string | null) {
    this.inputOptions.startDate = date;
  }

  /**
   * 配信日（終了）UIDatePickerのコールバック
   *
   * @param date 選択日付
   */
  changeEndDateCallback(date: string | null) {
    this.inputOptions.endDate = date;
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async editClickCallback(item: ListItem) {
    await this.$router.push({
      name: "push-notifications-edit",
      params: { id: String(item.id) },
      query: {
        shopId: String(this.shopId),
        shopName: this.shopName,
        pushLinkFlg: String(this.pushLinkFlg),
        // NEW_DEV-1437 cyber start
        ignoreFollowFlg: String(item.ignoreFollowFlg)
        // NEW_DEV-1437 cyber end
      }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: ListItem) {
    this.showDialog = true;
    this.deletingId = item.id;
    this.deletingTitle = item.title;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    this.isLoading = true;
    await PushModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (PushModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "プッシュメッセージを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: PushModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * テーブル行の固定表示・解除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  changeFixedClickCallback(item: ListItem) {
    this.showChangeDialog = true;
    this.fixedChangeId = item.id;
    this.fixedChangeTitle = item.title;
    this.fixedStatus = item.fixedFlg;
  }

  /**
   * ダイアログの固定表示のコールバック
   */
  async doChangeFixedOnDialog() {
    await Flash.clear();
    this.showChangeDialog = false;
    if (this.fixedChangeId <= 0) {
      return;
    }

    this.isLoading = true;
    await PushChangeFixed.changeFixedOne({
      id: this.fixedChangeId
    } as ChangeFixedRequest);
    if (PushChangeFixed.isSuccess) {
      await Flash.setSuccessNow({
        message: "プッシュメッセージの固定表示を変更しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: PushChangeFixed.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let request = this.createRequest();
    await PushSearch.search(request);
    if (!PushSearch.isSuccess) {
      await Flash.setErrorNow({
        message: PushSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  goToNew(ignoreFollowFlg: string | null) {
    this.$router
      .push({
        name: "push-notifications-new",
        query: {
          shopId: String(this.shopId),
          shopName: this.shopName,
          pushLinkFlg: String(this.pushLinkFlg),
          ignoreFollowFlg: String(ignoreFollowFlg ? ignoreFollowFlg : "0")
        }
      })
      .then();
  }
  // NEW_DEV-1437 cyber end

  //<!-- 20201216 NEW_DEV-734 C32-No.75、No.92、No.93 cyber房 start -->
  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    request.shopId = this.shopId;
    request.sendFlg = this.inputOptions.sendFlg;

    if (this.inputOptions.title) {
      request.title = this.inputOptions.title;
    } else {
      delete request.title;
    }
    if (this.inputOptions.startDate) {
      request.startDate = this.inputOptions.startDate;
    } else {
      delete request.startDate;
    }
    if (this.inputOptions.endDate) {
      request.endDate = this.inputOptions.endDate;
    } else {
      delete request.endDate;
    }
    if (this.inputOptions.status === 1) {
      request.status = this.inputOptions.status;
    } else {
      delete request.status;
    }
    return request;
  }
  //<!-- 20201216 NEW_DEV-734 C32-No.75、No.92、No.93 cyber房 end -->
}
